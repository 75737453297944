import {Component,Input,OnInit} from '@angular/core';

@Component({
  selector: 'dm-cart-breadcrumbs',
  templateUrl: './dm-card-breadcrumbs.component.html',
  styleUrls: ['./dm-card-breadcrumbs.component.scss']
})
export class DmCardBreadcrumbsComponent implements OnInit {

  @Input("content") content = 1;

  constructor(){}

  /***
   * 
   * Init functions
   * 
   */
  ngOnInit():void{}

}
