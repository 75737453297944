import { Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'galeria-fotorama',
  templateUrl: './galeria-fotorama.component.html',
  styleUrls: ['./galeria-fotorama.component.scss']
})
export class GaleriaFotoramaComponent implements OnInit {

  @Input("data") data:any           = [];
  @ViewChild("gallery") gallery:any = null;

  constructor(){}
    
  /**
   * 
   * Create
   * 
   */
  create(){

    $(this.gallery.nativeElement).fotorama();

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * init
   * 
   */
  ngAfterViewInit(): void {
    this.create();
  }

}
