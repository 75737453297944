<div class="dm-cart-breadcrumbs">
      <div class="container">
            <ul>
                  <li [ngClass]="{'active': content == 1}">
                        <a href="javascript::void()" title="Carrinho de Compras">
                              Carrinho de Compras
                        </a>
                  </li>
                  <li [ngClass]="{'active': content == 2}">
                        <a href="javascript::void()" title="Pagamento">
                              Pagamento
                        </a>
                  </li> 
                  <li [ngClass]="{'active': content == 3}"> 
                        <a href="javascript::void()" title="Confirmação">
                              Confirmação
                        </a>
                  </li>      
            </ul>      
      </div>
</div>      