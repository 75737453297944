import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaleriaFotoramaComponent } from '../../../components/galeria/galeria-fotorama/galeria-fotorama.component';
import { PartialModule } from '../../partial/partial.module';


@NgModule({
  declarations: [GaleriaFotoramaComponent],
  exports: [GaleriaFotoramaComponent],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class GaleriaFotoramaModule { }
