import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmCartResumoStepsComponent } from 'src/app/components/carrinho/dm-cart-resumo-steps/dm-cart-resumo-steps.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    DmCartResumoStepsComponent
  ],
  exports: [
    DmCartResumoStepsComponent
  ],
  imports: [  
    LazyLoadImageModule,
    CommonModule
  ]
})
export class CartResumoStepsModule { }
